<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card class="performance-card" no-body>
      <template #header>
        <div class="d-flex align-items-center">
          <b-icon-bar-chart-fill
            class="rounded-lg bg-brand-secondary p-3 mr-3"
            style="width: 40px; height: 40px"
            variant="white"
          ></b-icon-bar-chart-fill>
          <div>
            <h4 class="mb-0">{{ $t("Performance") }}</h4>
            <p class="mb-0">{{ periodTitle }}</p>
          </div>
        </div>
        <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item
            v-for="(option, optionIndex) in periodOptions"
            :key="`period-option-${optionIndex}`"
            @click="selectPeriodOption(option)"
          >
            {{ getPeriodOptionName(option) }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <b-card-body>
        <v-select
          v-if="showAccommodationSelector"
          v-model="selectedAccommodation"
          :options="accommodationOptions"
          :reduce="(option) => option.value"
          :clearable="false"
          :searchable="false"
          :selectable="(option) => !option.disabled"
          class="w-100 mb-5"
        />
        <b-row>
          <b-col cols="12" md="6">
            <performance-card-item :title="$t('Income')" :value="income">
              <feather-icon size="18" icon="DollarSignIcon" />
            </performance-card-item>
          </b-col>
          <b-col cols="12" md="6">
            <performance-card-item
              :title="$t('Bookings')"
              :value="bookingsNumber"
            >
              <feather-icon size="18" icon="BriefcaseIcon" />
            </performance-card-item>
          </b-col>
          <b-col cols="12" md="6">
            <performance-card-item :title="$t('Nights')" :value="nights">
              <feather-icon size="18" icon="MoonIcon" />
            </performance-card-item>
          </b-col>
          <b-col v-if="averageRating" cols="12" md="6">
            <performance-card-item :title="$t('Rating')" :value="averageRating">
              <feather-icon size="18" icon="AwardIcon" />
            </performance-card-item>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardBody,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BIconBarChartFill,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { formatCurrency } from "@formatters";
import { MAX_REVIEW_RATING } from "@appConfig";
import PerformanceCardItem from "@/views/home/components/PerformanceCardItem.vue";

export default {
  components: {
    BIconBarChartFill,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardBody,
    BDropdown,
    BDropdownItem,
    vSelect,
    PerformanceCardItem,
  },
  data() {
    return {
      selectedPeriod: "CURRENT_YEAR_FORECAST",
      selectedAccommodation: "ALL",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    contracts() {
      return this.$store.getters["owner/contracts"];
    },
    bookings() {
      return this.$store.getters["owner/bookings"];
    },
    accommodations() {
      return this.$store.getters["owner/accommodations"];
    },
    storedPerformancePeriod() {
      return this.$store.getters["ui/performancePeriod"];
    },
    storedPerformanceFilter() {
      return this.$store.getters["ui/performanceFilter"];
    },
    filteredBookings() {
      const currentYear = new Date().getFullYear();

      return this.bookings.filter((booking) => {
        // We already know that all the bookings are eiteh CONFIRMED, COMPLETED or CANCELLED

        // If the booking is cancelled, don't take it in consideration
        if (booking.cancelled) return false;

        // If the selected period is not a forecast, only take in consideration the completed bookings
        if (
          this.selectedPeriod !== "CURRENT_YEAR_FORECAST" &&
          !booking.completed
        ) {
          return false;
        }

        // Init vars
        const bookingCheckinDate = this.$moment(
          booking.checkin.split("T")[0],
          "YYYY-MM-DD"
        ).startOf("day");
        const bookingCheckinYear = bookingCheckinDate.year();
        let matchDates = false;

        switch (this.selectedPeriod) {
          case "CURRENT_YEAR_FORECAST":
            matchDates = bookingCheckinYear === currentYear;
            break;
          default:
            matchDates = bookingCheckinYear === this.selectedPeriod;
            break;
        }

        return (
          !!booking.ownerPrice &&
          matchDates &&
          (booking.accommodation.uuid === this.selectedAccommodation ||
            this.selectedAccommodation === "ALL")
        );
      });
    },
    periodOptions() {
      const textOptions = ["CURRENT_YEAR_FORECAST"];
      if (!this.bookings.length) return textOptions;

      const currentYear = new Date().getFullYear();
      let minYear = currentYear;

      this.bookings.forEach((booking) => {
        if (!booking.ownerPrice) return;
        const bookingCheckinYear = booking.checkin
          ? this.$moment(booking.checkin.split("T")[0], "YYYY-MM-DD").year()
          : null;
        if (bookingCheckinYear < minYear) minYear = bookingCheckinYear;
      });

      const yearOptions = this.getYearsBetween(minYear, currentYear);
      yearOptions.sort((a, b) => b - a); // sort descending

      return textOptions.concat(yearOptions);
    },
    accommodationOptions() {
      if (!this.accommodations.length) return [];

      const options = [];

      if (this.accommodations.length > 1) {
        options.push({
          label: this.$t("All accommodations"),
          value: "ALL",
        });
      }

      this.accommodations.forEach((accommodation) => {
        options.push({
          value: accommodation.uuid,
          label: accommodation.name,
        });
      });

      return options;
    },
    showAccommodationSelector() {
      return this.accommodations.length > 1;
    },
    periodTitle() {
      if (this.selectedPeriod === "CURRENT_YEAR_FORECAST") {
        const currentYear = new Date().getFullYear();
        return `${currentYear} (${this.$t("Forecast").toLowerCase()})`;
      }

      return this.selectedPeriod;
    },
    income() {
      if (!this.filteredBookings.length) {
        return "0,00 €";
      }

      // Create a unique array with the contracts with fixed season price
      const contractsWithFixedSeasonPrice = [];
      this.filteredBookings.forEach((booking) => {
        // Check if the booking's contract has a fixed season price
        if (booking?.contract?.fixedSeasonPrice) {
          // Check if the contract has already been added to the contractsWithFixedSeasonPrice array
          const foundContract = contractsWithFixedSeasonPrice.find(
            (contract) => contract.uuid === booking.contract.uuid
          );

          // If not found, add the contract to the contractsWithFixedSeasonPrice array
          if (!foundContract) {
            contractsWithFixedSeasonPrice.push(booking.contract);
          }
        }
      });

      // Calculate the total fixed season price
      const totalFixedPrice = contractsWithFixedSeasonPrice.reduce(
        (acc, contract) => acc + (contract.fixedSeasonPrice || 0),
        0
      );

      // Calculate the income coming from the bookings that doesn't have a fixed season price contract
      const totalVariablePrice = this.filteredBookings.reduce(
        (acc, booking) => {
          if (booking?.contract?.fixedSeasonPrice) {
            return acc;
          } else {
            return acc + (booking.ownerPrice || 0);
          }
        },
        0
      );

      return formatCurrency(totalFixedPrice + totalVariablePrice);
    },
    bookingsNumber() {
      const bookingsNumber = this.filteredBookings?.length || 0;
      return `${bookingsNumber}`;
    },
    averageRating() {
      if (!this.filteredBookings.length) return null;
      // Figure out how many bookings have a posted review
      const bookingsWithReview = this.filteredBookings.filter(
        (booking) =>
          !!booking.review?.postedAt &&
          typeof booking.review?.averageRating === "number"
      );
      if (!bookingsWithReview.length) return null;

      // Calculate the average rating based only on that bookings
      let averageRating = bookingsWithReview.reduce((acc, booking) => {
        if (!booking.review?.averageRating) return acc;
        return acc + booking.review.averageRating;
      }, 0);
      averageRating =
        Math.round((averageRating / bookingsWithReview.length) * 10) / 10;
      return `${averageRating} / ${MAX_REVIEW_RATING}`;
    },
    nights() {
      if (!this.filteredBookings.length) return "0";
      const nights = this.filteredBookings.reduce(
        (acc, booking) => acc + (booking.nights || 0),
        0
      );
      return `${nights}`;
    },
  },
  watch: {
    selectedPeriod(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("ui/setPerformancePeriod", newValue);
      }
      if (!this.accommodationFitsSelectedPeriod()) {
        this.selectedAccommodation = "ALL";
      }
    },
    selectedAccommodation(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("ui/setPerformanceFilter", newValue);
      }
    },
    accommodationOptions() {
      this.selectedAccommodation = this.accommodationOptions[0].value;
    },
  },
  mounted() {
    this.initFilters();
  },
  methods: {
    initFilters() {
      if (this.storedPerformancePeriod) {
        this.selectedPeriod = this.storedPerformancePeriod;
      }
      if (this.storedPerformanceFilter) {
        this.selectedAccommodation = this.storedPerformanceFilter;
      }
    },
    selectPeriodOption(period) {
      this.selectedPeriod = period;
    },
    getPeriodOptionName(option) {
      const currentYear = new Date().getFullYear();
      switch (option) {
        case "CURRENT_YEAR_FORECAST":
          return this.$t("Current year forecast");
        case currentYear:
          return this.$t("Current year");
        default:
          return option;
      }
    },
    contractFitsSelectedPeriod(contract, selectedPeriod) {
      if (!contract.startDate) return false;

      const contractStartDate = contract.startDate
        ? this.$moment(contract.startDate.split("T")[0], "YYYY-MM-DD").startOf(
            "day"
          )
        : null;
      const contractEndDate = contract.endDate
        ? this.$moment(contract.endDate.split("T")[0], "YYYY-MM-DD").startOf(
            "day"
          )
        : null;

      let selectedPeriodYearStart = null;
      let selectedPeriodYearEnd = null;

      if (selectedPeriod === "CURRENT_YEAR_FORECAST") {
        const currentYear = new Date().getFullYear();
        selectedPeriodYearStart = this.$moment(`${currentYear}`).startOf(
          "year"
        );
        selectedPeriodYearEnd = this.$moment(`${currentYear}`).endOf("year");
      } else {
        selectedPeriodYearStart = this.$moment(`${selectedPeriod}`).startOf(
          "year"
        );
        selectedPeriodYearEnd = this.$moment(`${selectedPeriod}`).endOf("year");
      }

      return (
        contractStartDate.isSameOrBefore(selectedPeriodYearEnd, "day") &&
        contractEndDate.isSameOrAfter(selectedPeriodYearStart, "day")
      );
    },
    accommodationFitsSelectedPeriod() {
      const accommodationContracts = this.contracts.filter(
        (contract) =>
          contract.accommodation?.uuid === this.selectedAccommodation
      );
      if (!accommodationContracts.length) return false;
      return !!accommodationContracts.find((contract) =>
        this.contractFitsSelectedPeriod(contract, this.selectedPeriod)
      );
    },
    getYearsBetween(year1, year2) {
      const years = [];
      for (let i = year1; i <= year2; i += 1) {
        const foundYearInList = years.find((y) => y === i);
        if (!foundYearInList) years.push(i);
      }

      return years;
    },
    bookingBelongsToContract(booking, contract) {
      return booking.contract.uuid === contract.uuid;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.performance-card {
  .card-header {
    .card-title {
      margin-bottom: 1.53rem !important; // Considering sub-title minus margin
    }
  }
}
</style>
