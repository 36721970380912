<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <b-icon-award-fill
            class="rounded-lg bg-brand-secondary p-3 mr-3"
            style="width: 40px; height: 40px"
            variant="light"
          ></b-icon-award-fill>
          <h4 class="mb-0">{{ $t("Last reviews") }}</h4>
        </div>
      </template>
      <div
        v-for="(review, reviewIndex) in filteredReviews"
        :key="`review-preview-${review.uuid}`"
        :class="{
          'border-bottom mb-4 pb-4': reviewIndex < filteredReviews.length - 1,
        }"
      >
        <review-card :review="review" :max-rating="MAX_REVIEW_RATING" compact />
      </div>
      <b-button
        block
        variant="primary"
        class="mt-4"
        :to="{ name: 'foravila-reviews-list' }"
      >
        {{ $t("pages.home.allReviews") }}
      </b-button>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton, BCard, BIconAwardFill } from "bootstrap-vue";
import ReviewCard from "@/views/reviews/list/components/ReviewCard.vue";
import { MAX_REVIEW_RATING } from "@appConfig";

export default {
  components: {
    BIconAwardFill,
    BOverlay,
    BButton,
    BCard,
    ReviewCard,
  },
  data() {
    return {
      REVIEWS_AMOUNT: 4,
      MAX_REVIEW_RATING,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    reviews() {
      return this.$store.getters["owner/reviews"];
    },
    filteredReviews() {
      if (!this.reviews.length) return [];
      return this.reviews.slice(0, this.REVIEWS_AMOUNT);
    },
  },
};
</script>
