<template>
  <b-card-actions
    action-collapse
    :collapsed="true"
    class="planning-collapsable-list border border-top-0"
    header-variant="light"
    :no-right-actions="!bookings.length"
    :no-body="!bookings.length"
    :disabled="!bookings.length"
  >
    <template #left-actions>
      <b-badge
        :variant="bookings.length ? 'primary' : 'light-primary'"
        class="no-select"
      >
        {{ bookings.length }}
      </b-badge>
      <span v-if="listTitle" class="no-select ml-2">
        {{ listTitle }}
      </span>
    </template>

    <b-card-body class="mt-4 p-0">
      <b-row>
        <b-col>
          <div
            v-for="(booking, bookingIndex) in bookings"
            :key="`planning-${bookingType}-${booking.uuid}`"
          >
            <mobile-bookings-list-item
              :booking="booking"
              :hide-accommodation-name="hideAccommodationName"
              :hide-checkout="bookingType === 'CHECKIN'"
              :hide-checkin="bookingType === 'CHECKOUT'"
              :hide-nights="bookingType === 'CHECKOUT'"
              :departure-badge="bookingType === 'CHECKOUT'"
              @booking-selected="onBookingSelected"
            />
            <hr v-if="bookingIndex < bookings.length - 1" class="my-3" />
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-actions>
</template>

<script>
import { BCardBody, BRow, BCol, BBadge } from "bootstrap-vue";
import BCardActions from "@/@foravila-core/components/b-card-actions/BCardActions.vue";
import MobileBookingsListItem from "@/views/bookings/list/components/MobileBookingsListItem.vue";

export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCardBody,
    BCardActions,
    MobileBookingsListItem,
  },
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    bookingType: {
      type: String,
      required: true,
      default: "CHECKIN",
    },
  },
  computed: {
    hideAccommodationName() {
      return !this.$store.getters["ui/showAccommodationName"];
    },
    listTitle() {
      if (this.bookingType === "CHECKIN") return this.$t("Check-ins");
      if (this.bookingType === "CHECKOUT") return this.$t("Check outs");
      return null;
    },
  },
  methods: {
    onBookingSelected(localizator) {
      this.$router.push({
        name: "foravila-booking-view",
        params: { localizator },
      });
    },
  },
};
</script>

<style lang="scss">
.planning-collapsable-list {
  .card {
    margin-bottom: 0 !important;
  }
}
</style>
