<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card>
      <h4 class="mb-3">{{ $t("pages.home.hi", { name: ownerName }) }} 👋</h4>
      <p class="mb-0">
        {{ $t("pages.home.welcomeText") }}
      </p>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";

export default {
  components: {
    BOverlay,
    BCard,
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    ownerName() {
      return this.owner?.firstName || null;
    },
  },
};
</script>
