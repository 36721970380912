<template>
  <b-media no-body class="d-flex align-items-center mb-4">
    <div>
      <b-avatar rounded size="42" variant="light-primary" class="mr-3">
        <slot />
      </b-avatar>
    </div>
    <div>
      <h4 class="font-weight-bolder mb-0">
        {{ value }}
      </h4>
      <span>
        {{ title }}
      </span>
    </div>
  </b-media>
</template>

<script>
import { BMedia, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BMedia,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>
