<template>
  <div>
    <b-row>
      <b-col cols="12" md="8">
        <welcome-card />
        <planning-card />
        <performance-card />
      </b-col>
      <b-col cols="12" md="4">
        <reviews-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import WelcomeCard from "@/views/home/components/WelcomeCard.vue";
import PerformanceCard from "@/views/home/components/PerformanceCard.vue";
import PlanningCard from "@/views/home/components/PlanningCard.vue";
import ReviewsCard from "@/views/home/components/ReviewsCard.vue";

export default {
  components: {
    BRow,
    BCol,
    WelcomeCard,
    PerformanceCard,
    PlanningCard,
    ReviewsCard,
  },
  computed: {
    owner() {
      return this.$store.getters["owner/owner"];
    },
    reviews() {
      return this.$store.getters["owner/reviews"];
    },
  },
  created() {
    if (!this.owner) this.fetchOwner();
  },
  methods: {
    fetchOwner() {
      const userData = JSON.parse(
        localStorage.getItem("foravilaOwnersUserData")
      );
      const ownerUuid = userData?.owner?.uuid || null;
      this.$store.dispatch("owner/fetchOwner", ownerUuid).catch(() => {
        // TODO: pensar que ha de passar si es fetchOwner ens dona error
      });
    },
  },
};
</script>
