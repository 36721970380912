<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card id="planning-card">
      <template #header>
        <div class="d-flex align-items-center">
          <b-icon-calendar-fill
            class="rounded-lg bg-brand-secondary p-3 mr-3"
            style="width: 40px; height: 40px"
            variant="light"
          ></b-icon-calendar-fill>
          <h4 class="mb-0">{{ $t("Planning") }}</h4>
        </div>
      </template>
      <v-select
        v-model="selectedPeriod"
        :options="periodOptions"
        :reduce="(option) => option.value"
        :clearable="false"
        :searchable="false"
        class="mb-4"
      />

      <!-- CHECKINS -->
      <planning-collapsable-bookings-list
        :bookings="filteredCheckins"
        booking-type="CHECKIN"
        :selected-period="selectedPeriod"
      />

      <!-- CHECKOUTS -->
      <planning-collapsable-bookings-list
        :bookings="filteredCheckouts"
        booking-type="CHECKOUT"
        :selected-period="selectedPeriod"
        class="mb-4"
      />

      <b-button
        block
        variant="primary"
        :to="{ name: 'foravila-bookings-list' }"
        :disabled="!bookings.length"
      >
        {{ $t("pages.home.allBookings") }}
      </b-button>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BButton, BIconCalendarFill } from "bootstrap-vue";
import vSelect from "vue-select";
import PlanningCollapsableBookingsList from "@/views/home/components/PlanningCollapsableBookingsList.vue";

export default {
  components: {
    BIconCalendarFill,
    BOverlay,
    BCard,
    vSelect,
    BButton,
    PlanningCollapsableBookingsList,
  },
  data() {
    return {
      selectedPeriod: "7_DAYS",
    };
  },
  computed: {
    loading() {
      return this.$store.getters["owner/loading"];
    },
    owner() {
      return this.$store.getters["owner/owner"];
    },
    bookings() {
      return this.$store.getters["owner/bookings"];
    },
    filteredCheckins() {
      return this.bookings
        .filter((booking) => {
          if (!booking.confirmed) return false;
          const bookingCheckinDate = this.$moment(
            booking.checkin.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");
          const today = this.$moment().startOf("day");

          switch (this.selectedPeriod) {
            case "TODAY":
              return bookingCheckinDate.isSame(today, "day");
            case "TOMORROW":
              return bookingCheckinDate.isSame(
                this.$moment().add(1, "d").startOf("day"),
                "day"
              );
            case "7_DAYS":
              return bookingCheckinDate.isBetween(
                today,
                this.$moment().add(1, "w").startOf("day"),
                "day",
                "[]"
              );
            case "15_DAYS":
              return bookingCheckinDate.isBetween(
                today,
                this.$moment().add(2, "w").startOf("day"),
                "day",
                "[]"
              );
            default:
              return false;
          }
        })
        .sort((a, b) => new Date(a.checkin) - new Date(b.checkin))
        .map((booking) => ({
          uuid: booking.uuid || this.$t("Not defined"),
          date: new Date(booking.date) || this.$t("Not defined"),
          localizator: booking.localizator || this.$t("Not defined"),
          status: booking.status || this.$t("Not defined"),
          source: booking.source || this.$t("Not defined"),
          client: booking.client?.fullName || this.$t("Not defined"),
          accommodation: booking.accommodation?.name || this.$t("Not defined"),
          checkin: new Date(booking.checkin) || this.$t("Not defined"),
          checkinTime: booking.details?.accommodationArrivalTime || null,
          checkout: new Date(booking.checkout) || this.$t("Not defined"),
          checkoutTime: booking.details?.accommodationDepartureTime || null,
          adults: booking.adults || null,
          children: booking.children || null,
          babies: booking.babies || null,
          nights: booking.nights || null,
        }));
    },
    filteredCheckouts() {
      return this.bookings
        .filter((booking) => {
          if (!booking.confirmed && !booking.completed) return false;
          const bookingCheckoutDate = this.$moment(
            booking.checkout.split("T")[0],
            "YYYY-MM-DD"
          ).startOf("day");
          const today = this.$moment().startOf("day");

          switch (this.selectedPeriod) {
            case "TODAY":
              return bookingCheckoutDate.isSame(today, "day");
            case "TOMORROW":
              return bookingCheckoutDate.isSame(
                this.$moment().add(1, "d").startOf("day"),
                "day"
              );
            case "7_DAYS":
              return bookingCheckoutDate.isBetween(
                today,
                this.$moment().add(1, "w").startOf("day"),
                "day",
                "[]"
              );
            case "15_DAYS":
              return bookingCheckoutDate.isBetween(
                today,
                this.$moment().add(2, "w").startOf("day"),
                "day",
                "[]"
              );
            default:
              return false;
          }
        })
        .sort((a, b) => new Date(a.checkout) - new Date(b.checkout))
        .map((booking) => ({
          uuid: booking.uuid || this.$t("Not defined"),
          date: new Date(booking.date) || this.$t("Not defined"),
          localizator: booking.localizator || this.$t("Not defined"),
          status: booking.status || this.$t("Not defined"),
          source: booking.source || this.$t("Not defined"),
          client: booking.client?.fullName || this.$t("Not defined"),
          accommodation: booking.accommodation?.name || this.$t("Not defined"),
          checkin: new Date(booking.checkin) || this.$t("Not defined"),
          checkinTime: booking.details?.accommodationArrivalTime || null,
          checkout: new Date(booking.checkout) || this.$t("Not defined"),
          checkoutTime: booking.details?.accommodationDepartureTime || null,
          adults: booking.adults || null,
          children: booking.children || null,
          babies: booking.babies || null,
          nights: booking.nights || null,
        }));
    },
    periodOptions() {
      return [
        { label: this.$t("Today"), value: "TODAY" },
        { label: this.$t("Tomorrow"), value: "TOMORROW" },
        { label: this.$t("pages.home.7days"), value: "7_DAYS" },
        { label: this.$t("pages.home.15days"), value: "15_DAYS" },
      ];
    },
    storedPlanningFilter() {
      return this.$store.getters["ui/planningFilter"];
    },
  },
  watch: {
    selectedPeriod(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("ui/setPlanningFilter", newValue);
      }
    },
  },
  mounted() {
    this.initFilter();
  },
  methods: {
    initFilter() {
      if (!this.storedPlanningFilter) return;
      this.selectedPeriod = this.storedPlanningFilter;
    },
    onCalendarIconClick() {
      this.$router.push({
        name: "foravila-bookings-calendar",
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#planning-card {
}
</style>
